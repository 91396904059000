<template>
  <section v-if="payload.references.length" class="referenz-teaser part">
    <div v-view class="constrain animate in-bottom">
      <div class="header-line">
        <h2 v-html="payload.titel" />
        <lpgmbh-button :to="payload.discoverAllLink" inverted>
          {{ payload.discoverAllText }}
        </lpgmbh-button>
      </div>
      <slider
        ref="sliderRef"
        v-slot="{ item }"
        :items="payload.references"
        :slides-per-view="{ desktop: 2, mobile: 1.1 }"
        :slides-per-group="{ desktop: 2, mobile: 1 }"
        @progress="onProgress"
        @afterInit="updatePage"
        @slideChange="updatePage"
        @resize="updatePage"
      >
        <Go :to="item.link" class="block">
          <box class="slide-box" background>
            <div class="corner">
              <span>{{ item.jahr }}</span>
              /
              <span>{{ item.leistung?.titel }}</span>
            </div>
            <div class="content">
              <h3 v-html="item.titel" />
              <span v-html="item.vorschautext" />
            </div>
            <box
              :force-aspect-ratio="isDesktop"
              :sync-height="isDesktop"
              class="image"
            >
              <Image :src="item.vorschaubild" />
            </box>
          </box>
        </Go>
      </slider>
      <page-progress
        class="progress"
        :start="start"
        :end="end"
        :current-page="page"
        :pages="pages"
        @prev="prev"
        @next="next"
      />
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { useVisibleProgress, usePage } from '@/composables/swiper';
import { useWithinBreakpoint } from '@/composables/breakpoints';
import Slider from '../utils/Slider.vue';

export default {
  components: { Slider },
  props: { payload: { type: Object, default: () => ({}) } },
  setup() {
    const sliderRef = ref(null);

    const isDesktop = useWithinBreakpoint('md');
    const { onProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    return {
      isDesktop,
      sliderRef,
      page,
      pages,
      start,
      end,
      prev,
      next,
      onProgress,
      updatePage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.referenz-teaser {
  @include responsive('margin-top', px(73), px(338));

  &:last-of-type {
    @include responsive('margin-bottom', px(81), px(227));
  }
}

.header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include responsive('margin-bottom', px(23), px(66));
}

.slide-box {
  @apply bg-secondary;

  display: flex;
  position: relative;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 100%;

  @include responsive('flex-direction', column, row);

  h3 {
    @include responsive('margin-top', 0 px(83) px(83) px(83), 0 px(67) px(67) px(67));
    @include responsive('margin-bottom', px(20), px(15));
  }

  .corner {
    @apply bg-blue-teal;
    @apply text-white;
    @apply rounded-br-big;

    position: absolute;
    left: 0;
    top: 0;

    @include responsive('font-size', px(12), px(14));
    @include responsive('line-height', px(16), px(19));
    @include padding(px(12), px(20) px(40))
  }

  .content {
    @include responsive('order', 2, 1);
    @include padding(px(15) px(24) px(17) px(24), px(128) px(18) px(108) px(36));
  }

  .image {
    margin-left: auto;
    flex: 0 1 auto;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    padding: 0;

    @include responsive('height', 25vw, unset);
    @include responsive('order', 1, 2);

    @screen md {
      left: initial;
      right: 0;
      top: 0;
      max-width: px(342);
    }

    :deep(img) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.progress {
  @include responsive('margin-top', px(14), px(42));
}

:deep(.swiper-container) {
  @include responsive('overflow', visible, hidden);
}

</style>
